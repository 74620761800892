import { Cookies } from "react-cookie-consent";

const gtmEnabled = 'gtm-enabled';

export const onPageLoadEvent = () => {
  if (window.dataLayer){
    window.dataLayer.push({event: 'pageLoaded'});
  }
  if (isGtmEnabled()) {
    window.dataLayer.push({event: 'gdpr_cookie_accepted'});
  }
}

export const onPageChangeEvent = () => {
  if(window.dataLayer){
    window.dataLayer.push({event: 'pageChange'});
  }
}

export const logFormSubmission = formName => {
  typeof window !== "undefined" &&
  typeof window.gtag !== "undefined" &&
    window.gtag("event", "form_submission", { form_name: formName });
};

export const isGtmEnabled = () => {
  return Cookies.get(gtmEnabled) === "true";
}

export const setGtmEnabledOn = (location, autoAccept) => {

  if (!isGtmEnabled()) {
    Cookies.set(gtmEnabled, true);

    const fakeLink = window.document.createElement("a");
    if (autoAccept) {
      fakeLink.setAttribute("href", "#non-eu");
    } else {
      fakeLink.setAttribute("href", "#accept_cookies");
    }
    fakeLink.click();

    window.dataLayer.push({event: 'gdpr_cookie_accepted'});
  }
};