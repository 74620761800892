// all styles here
import "./static/fonts/fonts.css";
import "./src/assets/css/bootstrap.css";
import "./src/assets/css/animate.min.css";
import "./src/assets/css/boxicons.min.css";
import "./src/assets/css/flaticon.css";
import "./node_modules/react-modal-video/css/modal-video.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "./src/assets/css/style.css";
import "./src/assets/css/responsive.css";

import "./src/assets/css/_typography.css";
import "./src/assets/css/_images.css";
import "./src/assets/css/_clock.css";
import "./src/assets/css/_slider.css";
import "./src/assets/css/_carousel.css";
import "./src/assets/css/_blocks.css";
import "./src/assets/css/_nav.css";

import "./src/assets/css/_custom.css";
import "./src/assets/css/_footer.css";
import "./src/assets/css/_alignment.css";
import "./src/assets/css/_mobilemenu.css";

import "./src/assets/css/_accordion.css";
import "./src/assets/css/_forms.css";
import "./src/assets/css/_ourculture.css";
import "./src/assets/css/_map.css";
import "./src/assets/css/_apply.css";

import "./src/assets/css/_campaigns.css";
import {
  onPageChangeEvent,
  onPageLoadEvent,
} from "./src/utils/google-tag-manager";

export function onInitialClientRender() {
  onPageLoadEvent();
}

export function onRouteUpdate({ location, prevLocation }) {
  onPageChangeEvent();
}